import React from "react"
import Slider from "react-slick"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./CareerSlider.scss"

const CareerSlider = ({ data,count }) => {
  const { isTabletOnly,isMobile } = useDeviceMedia()

  const settings = {
    dots: isMobile?true: false,
    infinite: true,
    slidesToShow:isMobile?1: isTabletOnly ? 2.3 : 4.4,
    slidesToScroll: 1,
    arrows: false,
    speed:isMobile?500: 4000,
    autoplaySpeed:isMobile?500:4000,
    autoplay: isMobile?false:true,
    cssEase: "linear",
    rtl:count==="1"?true:false
  }

  return (
    <div className={`career-slider-wrapper ${data?.type}`}>
      <div className="career-slider_container">
        <div className={`image_section_module ${count?"section-"+count:""}`}>
          <Slider {...settings}>
            {data?.add_image?.map((list, index) => {
              return (
                <div className="image-list">
                  <img src={list?.images?.url} alt="Image Slider - haus & haus"/>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default CareerSlider
