import { graphql } from "gatsby"
import React from "react"
import AccordianValuation from "../components/AccordianValuation/AccordianValuation"
import CareerSlider from "../components/CareerSlider/CareerSlider"
import GridGallery from "../components/GridGallery/GridGallery"
import GuidesCardComponent from "../components/GuidesCardComponent/GuidesCardComponent"
import ImageContentComponent from "../components/ImageContentComponent/ImageContentComponent"
import LandingBanner from "../components/LandingBanner/LandingBanner"
import LandingContent from "../components/LandingContent/LandingContent"
import PageWithIcon from "../components/PageWithIcon/PageWithIcon"
import SecondaryPageBanner from "../components/SecondaryPageBanner/SecondaryPageBanner"
// import MortgageCalculator from "../components/MortgageCalculator/MortgageCalculator"
import Layout from "../components/layout"
import SEO from "../components/seo"
import useDeviceMedia from "../hooks/useDeviceMedia"
import MortgageCalculator from "../components/MortgageCalculator/MortgageCalculator"
import { Container } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll"
const LandingPageTemplate = ({ data }) => {
  const { isMobile } = useDeviceMedia()
  const pageData = data?.strapiPage

  let popUpData=""

  if(pageData?.add_page_modules?.length>0){
     const results=pageData?.add_page_modules?.filter(item=>item.__typename==="STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT")
     if(results?.length>0){
      popUpData=results[0]
     }
  }

  return (
    <Layout popUpData={popUpData} popularSearch={pageData?.select_popular_search?.title}>
      {/* <SEO
        title={
          pageData?.seo?.metaTitle ? pageData?.seo?.metaTitle : pageData?.title
        }
        description={
          pageData?.seo?.metaDescription
            ? pageData?.seo?.metaDescription
            : pageData?.title
        }
      ></SEO> */}
      <LandingBanner pageData={pageData} />
      {pageData.add_page_modules?.map((item, i) => {
        return (
          <div>
            {item?.__typename === "STRAPI__COMPONENT_COMPONENTS_TITLE_DESC" && (
              <LandingContent data={item} />
            )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_VALUATION_IMAGE" && (
              <>
                {item.type === "grid" ? (
                  <GridGallery data={item} pageData={pageData} />
                ) :
                isMobile&& item.count==="1"?
                (
                  <CareerSlider data={item} pageData={pageData} />
                  // <CareerSlider data={item} pageData={pageData} count={item.count} />
                )
                :!isMobile&&
                <CareerSlider data={item} pageData={pageData} count={item.count} />
                }
              </>
            )}


            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT" && (
              <ImageContentComponent data={item} pageData={pageData} />
            )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_SECONDARY_BANNER" && (
              <div>
                <SecondaryPageBanner data={item} pageData={pageData} />
              </div>
            )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_ICON_CONTENT" &&
              (item?.layout === "page" ||
              item?.layout === "page_vertical_line" ? (
                <PageWithIcon
                  id={pageData?.strapi_id}
                  imagetransforms={
                    pageData.imagetransforms?.icon_section_image_Transforms
                  }
                  {...item}
                  count={3}
                />
              ) : null)}

            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&item?.select_module==="mortgage_calculator"&&
              <div className="mortgage-page-wrapper" id="mortage-calc-landing">
                <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
                <Container>
                  <MortgageCalculator landingPage/>
                </Container>
                </ScrollAnimation>
              </div>
              }
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_ACCORDION" && (
              <AccordianValuation
                add_toggle={item?.add}
                title={item?.title}
                content={item?.content}
              />
            )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GUIDES_CARDS" && (
              <GuidesCardComponent data={item} />
            )}
          </div>
        )
      })}
    </Layout>
  )
}


export const Head = ({ data }) => {
  const PageData = data?.strapiPage

  let myimgtransforms = ""
  if (PageData?.imagetransforms?.banner_section_banner_image_Transforms) {
    myimgtransforms = JSON.parse(
      PageData?.imagetransforms?.banner_section_banner_image_Transforms
    )
  }

  let imageUrl=""

  const findImage = PageData?.banner?.image?.url?.substring(PageData?.banner?.image?.url?.indexOf("i.prod"));
  const ProcessedResult=findImage&&myimgtransforms[findImage]?myimgtransforms[findImage]["webp"]:""
  imageUrl=ProcessedResult?ProcessedResult["768x397"]:PageData?.banner?.image?.url

  // let pageImg = ""
  // if (Object.keys(myimgtransforms)?.length > 0) {
  //   let pageImg_filt = []
  //   for (const myimgtransform in myimgtransforms) {
  //     if (typeof myimgtransforms[myimgtransform]["webp"] !== "undefined") {
  //       pageImg_filt = Object.values(myimgtransforms[myimgtransform]["webp"])
  //       break
  //     }
  //   }

  //   if (pageImg_filt.length > 0) pageImg = pageImg_filt.length>0?pageImg_filt[0]:PageData?.banner?.image?.url
  // }
  // const imageUrl=pageImg
  // const imageUrl=PageData?.banner?.image?.url;
  return (
      <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} 
      imageUrl={imageUrl}
      />
  )
}


export default LandingPageTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment

      add_page_modules {
        ... on STRAPI__COMPONENT_COMPONENTS_TITLE_DESC {
          __typename
          id
          description {
            data {
              description
            }
          }
          title
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_SECONDARY_BANNER {
          __typename
          id
          image {
            url
          }
          heading
          content {
            data {
              content
            }
          }
          description {
            data {
              description
            }
          }
          video_url
          title
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_ICON_CONTENT {
          __typename
          ...IconFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
          __typename
          id
          add {
            content {
              data {
                content
              }
            }
            title
          }
          title
          content {
            data {
              content
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_GUIDES_CARDS {
          __typename
          id
          heading

          items {
            cta_title
            content {
              data {
                content
              }
            }
            image {
              url
              alternativeText
            }
            link {
              ...MenuFragment
            }
            cta_title
            link_type
            custom_link
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_VALUATION_IMAGE {
          id
          __typename
          type
          title
          content {
            data {
              content
            }
          }
          count
          add_image {
            images {
              url
              alternativeText
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          __typename
          id
          select_module
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT {
          __typename
          id
          left_right_block {
            menu {
              slug
              strapi_parent {
                slug
              }
            }
            image {
              url
              alternativeText
            }
            title
            description {
              data {
                description
              }
            }
            video_url
            layout
            cta_label
            custom_link
            bg_type
            id_name
          }
        }
      }
    }
  }
`
